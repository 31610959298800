<script setup>
import { Head, Link } from '@inertiajs/vue3';

defineProps({
    canLogin: {
        type: Boolean,
    },
    canRegister: {
        type: Boolean,
    },
    laravelVersion: {
        type: String,
        required: true,
    },
    phpVersion: {
        type: String,
        required: true,
    },
});

function handleImageError() {
    document.getElementById('screenshot-container')?.classList.add('!hidden');
    document.getElementById('docs-card')?.classList.add('!row-span-1');
    document.getElementById('docs-card-content')?.classList.add('!flex-row');
    document.getElementById('background')?.classList.add('!hidden');
}
</script>

<template>
    <Head title="Welcome" />



    <Link :href="route('form.create')">新規作成</Link>





    <div v-if="canLogin">
        <Link
            v-if="$page.props.auth.user"
            :href="route('dashboard')"
        >
            Dashboard
        </Link>

        <template v-else>
            <Link
                :href="route('login')"
            >
                Log in
            </Link>

            <Link
                v-if="canRegister"
                :href="route('register')"
            >
                Register
            </Link>
        </template>
    </div>
</template>
